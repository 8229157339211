<template>
  <div>
    <div class="page-header">
      <h1>
        <span>{{ $t("reports") }} &ndash;</span>
        {{ $t("royalties") }}
      </h1>
    </div>

    <b-loading v-model="loading"></b-loading>

    <FilterAndSettings
      v-if="resultsFilter"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></FilterAndSettings>

    <ResultsTable
      v-if="resultsFilter"
      :loading="loading"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></ResultsTable>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapGetters } from "vuex";
import Currencies from "../../helpers/Currencies";
import RoyaltiesConfig from "./config/RoyaltiesConfig";
import FilterAndSettings from "./partials/FilterAndSettings";
import ResultsFilter from "./partials/ResultsFilter";
import ResultsTable from "./partials/ResultsTable";

export default {
  name: "ReportRoyalties",
  components: { FilterAndSettings, ResultsTable },
  data() {
    return {
      loading: true,
      config: new RoyaltiesConfig(),
      resultsFilter: null,
      settings: {
        currency: "EUR",
        conversionRate: Currencies.euroToDollarConversionRate,
      },
    };
  },
  computed: {
    ...mapGetters(["findCurrencyByUri"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllContracts"]),
    async load() {
      const contracts = await this.getAllContracts();

      for (const i of contracts) {
        i.date = i.startDate;
        i.expired = dayjs(i.endDate).isBefore(dayjs(), "day");
        i.currency = this.findCurrencyByUri(i.currency["@id"]);
        i.estimate = i.pricePerDistanceUnit * i.distanceUnits;
        i.devicesCount = `${i.nrOfDevices}/${i.nrOfContractProductDeviceUsages}`;
      }

      this.resultsFilter = new ResultsFilter(contracts);

      this.loading = false;
    },
  },
};
</script>
