import i18n from "../../../plugins/i18n";
import BaseConfig from "./BaseConfig";

export default class RoyaltiesConfig extends BaseConfig {
  filterDate = true;

  columns = [
    {
      label: i18n.t("start_date"),
      field: "startDate",
      component: "DateColumn",
    },
    {
      label: i18n.tc("contract"),
      field: "contractNumber",
      component: "StringColumn",
    },
    {
      label: i18n.tc("expired"),
      field: "expired",
      component: "BooleanColumn",
    },
    {
      label: i18n.t("cloud_customer"),
      field: "company.name",
      component: "StringColumn",
    },
    {
      label: i18n.tc("device", 2),
      field: "devicesCount",
      component: "NumberColumn",
      numeric: true,
    },
    {
      label: i18n.t("distance_units"),
      field: "distanceUnits",
      component: "DistanceColumn",
      numeric: true,
    },
    {
      label: i18n.t("measured_distance"),
      field: "totalLength",
      component: "DistanceColumn",
      numeric: true,
    },
    {
      label: i18n.t("estimated_royalty"),
      field: "estimate",
      component: "MoneyColumn",
      numeric: true,
    },
    {
      label: i18n.t("actual_royalty"),
      field: "royalty",
      component: "MoneyColumn",
      numeric: true,
    },
  ];
}
